import { logout, tokenRefreshed } from 'actions/auth';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import Config from 'config';
import { store } from 'store';
import { ILoginResponse } from 'types';
import analytics from './analytics.helper';
import sentry from './sentry.helper';

export interface IUploadSnapshot {
    lengthComputable: boolean;
    loaded: number;
    total: number;
}

const MESSAGES = {
    OFFLINE: 'You are offline, Please connect internet',
    NOT_REACHABLE: 'We are not able to connect to server at the moment',
    SESSION_EXPIRED: 'Your session has been expired, please login again.',
    UNKNOWN: 'Something went wrong, Please contact to administrator',
    REQUEST_CANCELLED: 'request cancelled by user',
};

interface IOptions {
    external?: boolean;
    headers?: { [key: string]: string };
    onUploadProgress?: (snapshot: IUploadSnapshot) => void;
}

class ApplicationError extends Error {
    public data: any;
    // eslint-disable-next-line
    constructor(message: string, data?: any) {
        super(message);
        this.data = data;
    }
}

// tslint:disable-next-line: max-classes-per-file
class NetworkService {
    private _cancelToken: CancelTokenSource = null;
    public MESSAGES = MESSAGES;

    private async handleError<T>(error: AxiosError<T> | ApplicationError, retryCount?: number): Promise<T> {
        this._cancelToken = null;
        if (axios.isCancel(error)) {
            throw new ApplicationError(MESSAGES.REQUEST_CANCELLED);
        }

        if (error instanceof ApplicationError) {
            throw error;
        }

        if (error.message === 'Network Error') {
            sentry.captureMessage('Server not reachable, user may offline.');
            throw new ApplicationError(MESSAGES.NOT_REACHABLE);
        }

        if (error.response) {
            if (error.response.status === 500) {
                sentry.captureMessage(`Http status 500 received, Body: ${JSON.stringify(error.response.data)}`);
                throw new ApplicationError(MESSAGES.UNKNOWN);
            }

            if (error.response.status === 400 && error.response.data && (error.response.data as any).message) {
                throw new ApplicationError((error.response.data as any).message, error.response.data);
            }
            
            if (error.response.status === 401) {
                if (retryCount) {
                    analytics.event('auth_expired');
                    store.dispatch(logout(true));
                    throw new ApplicationError(MESSAGES.SESSION_EXPIRED);
                } else {
                    const tokenRefreshSuccess = await this.refreshToken();
                    if (!tokenRefreshSuccess) {
                        analytics.event('auth_expired');
                        store.dispatch(logout(true));
                        throw new ApplicationError(MESSAGES.SESSION_EXPIRED);
                    } else {
                        return this.retry<T>(error.config, 1);
                    }
                }
            }

            sentry.captureMessage(`Unhandled response, Status: ${error.response.status}, Body: ${JSON.stringify(error.response.data)}`);
            throw new ApplicationError(MESSAGES.UNKNOWN);
        }

        sentry.captureException(error);
        throw new ApplicationError(MESSAGES.UNKNOWN);
    }

    private handleResponse<T>(response: AxiosResponse<T>) {
        this._cancelToken = null;
        if (response.status !== 200 && response.status !== 201) {
            throw new ApplicationError(MESSAGES.UNKNOWN);
        }

        return response.data;
    }

    private getHeader(options?: IOptions) {
        const header: { [key: string]: string } = {};

        if (options && options.headers) {
            Object.assign(header, options.headers);
        }

        if (options && options.external) {
            return header;
        }

        const authInfo = store.getState().auth.authInfo;
        const token = authInfo && authInfo.token;
        if (token) {
            header.Authorization = `Bearer ${token}`;
        }

        // header['X-Accept-Language'] = 'en-AU';
        // header['X-Franchisor-Id'] = '6';

        const franchisor = store.getState().auth.franchisor;
        if (franchisor) {
            header['X-Franchisor-Id'] = String(franchisor.data.id);
        }

        const selectedLanguage = store.getState().root.selectedLanguage;

        if (selectedLanguage) {
            header['X-Accept-Language'] = selectedLanguage.culture;
        }

        return header;
    }

    public async refreshToken(): Promise<boolean> {
        const authState = store.getState().auth;

        if (!authState.authenticated || !authState.authInfo) {
            return false;
        }

        try {
            const data = `grant_type=refresh_token&refresh_token=${encodeURIComponent(authState.authInfo.refreshToken)}`;
            const response = await axios.post<ILoginResponse>(`${Config.SERVER_URL}/token`, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            store.dispatch(tokenRefreshed({
                expireAt: response.data['.expires'],
                issuedAt: response.data['.issued'],
                refreshToken: response.data.refresh_token,
                token: response.data.access_token,
                tokenType: response.data.token_type,
                user: JSON.parse(response.data.user),
            }));
            return true;
        } catch (error) {
            return false;
        }
    }

    private async retry<T = any>(config: AxiosRequestConfig, retryCount: number) {
        try {
            config.headers = { ...config.headers, ...this.getHeader() };
            const response = await axios.request(config);
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError<T>(error, retryCount);
        }
    }

    async request<T = any>(config: AxiosRequestConfig) {
        try {
            config.headers = { ...config.headers, ...this.getHeader() };
            const response = await axios.request(config);
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError<T>(error);
        }
    }

    async get<T = any>(url: string, options?: IOptions) {
        try {
            const response = await axios.get<T>(url, {
                headers: this.getHeader(options),
            });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError<T>(error);
        }
    }

    async post<T = any, D = any>(url: string, data: D, options?: IOptions) {
        try {
            this._cancelToken = axios.CancelToken.source();
            const response = await axios.post<T>(url, data, {
                headers: this.getHeader(options),
                cancelToken: this._cancelToken.token,
                onUploadProgress: progressEvent => {
                    if (options && options.onUploadProgress) {
                        options.onUploadProgress({
                            lengthComputable: progressEvent.lengthComputable,
                            loaded: progressEvent.loaded,
                            total: progressEvent.total,
                        });
                    }
                },
            });
            return this.handleResponse<T>(response);
        } catch (error) {
            return this.handleError<T>(error);
        }
    }

    async put<T = any, D = any>(url: string, data: D, options?: IOptions) {
        try {
            this._cancelToken = axios.CancelToken.source();
            const response = await axios.put<T>(url, data, {
                headers: this.getHeader(options),
                cancelToken: this._cancelToken.token,
                onUploadProgress: progressEvent => {
                    if (options && options.onUploadProgress) {
                        options.onUploadProgress({
                            lengthComputable: progressEvent.lengthComputable,
                            loaded: progressEvent.loaded,
                            total: progressEvent.total,
                        });
                    }
                },
            });
            return this.handleResponse<T>(response);
        } catch (error) {
            return this.handleError<T>(error);
        }
    }

    async delete<T = any, D = any>(url: string, data: D, options?: IOptions) {
        try {
            const response = await axios.delete<T>(url, {
                headers: this.getHeader(options),
                data,
            });
            return this.handleResponse<T>(response);
        } catch (error) {
            return this.handleError<T>(error);
        }
    }

    async cancel() {
        if (this._cancelToken) {
            this._cancelToken.cancel('Cancelled by user');
        }
    }

    isCancelledByUser(error: Error): boolean {
        return Boolean(error) && error.message === MESSAGES.REQUEST_CANCELLED;
    }
}

export default new NetworkService();
