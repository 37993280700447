import analytics from '@app/helpers/analytics.helper';
import { selectCenters, selectedCenter } from '@app/selectors/auth';
import { IAppState } from '@app/store';
import { ICenter, IWorkshop } from '@app/types';
import classNames from 'classnames';
import Moment from 'moment-timezone';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { changeCenter, changeScreen, fetchWorkshops, fetchWorkshopsMore, selectWorkshop } from '../workshop-enrolment.actions';
import { selectState } from '../workshop-enrolment.selectors';
import { PATH } from '../workshop-enrolment.constants';

interface IDispatchProps {
  changeCenter: (id: number) => void;
  fetchWorkshops: () => void;
  fetchWorkshopsMore: () => void;
  selectWorkshop: (workshop: IWorkshop) => void;
}

interface IStateProps {
  centers: ICenter[];
  selectedCenter: ICenter;
  workshops: IWorkshop[];
  workshopsLoading: boolean;
  workshopsLoadingMore: boolean;
  totalWorkshops: number;
  selectedWorkshop: IWorkshop;
}

class ChooseWorkshop extends React.Component<IDispatchProps & IStateProps> {
  componentDidMount() {
    this.props.fetchWorkshops();
  }

  handleChangeCenter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();

    const value = e.target.value;

    this.props.changeCenter(Number(value));
  }

  renderWorkshops() {
    const { workshops, selectedWorkshop, totalWorkshops, workshopsLoadingMore } = this.props;

    return <React.Fragment>
      <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-row align-items-center flex-grow-1 mt-3 mb-2">
          <h4 className="mr-1">Workshops ({workshops.length})</h4>
        </div>
      </div>

      <div className="mt-1 row">
        {workshops.map(item => (
          <div className="col-md-4 pb-2" key={String(item.id)}>
            <button
              className={classNames(`card flex-row position-relative`, { 'border border-success': selectedWorkshop && item.id === selectedWorkshop.id })}
              style={{ width: '100%', height: 190 }}
              onClick={() => this.props.selectWorkshop(item)}
            >
              <div className="card-body d-flex flex-column align-items-center justify-content-center overflow-hidden">
                <h5>{item.name}</h5>
                <div className="d-flex flex-row align-items-center w-100 overflow-auto justify-content-center">
                  <small className='text-muted'>{Moment(item.startDate).format('LL')} - {Moment(item.endDate).format('LL')}</small>
                </div>
                <div className="mt-1 d-flex flex-column">
                  <small className="text-muted">{item.weeks.map(week =>
                    <div key={week.id}><b>{week.name}</b> {Moment(week.startDate).format('ddd Do MMM')} - {Moment(week.endDate).format('ddd Do MMM')}</div>)}</small>
                </div>
              </div>
              {Boolean(selectedWorkshop) && item.id === selectedWorkshop.id && (
                <div className="text-white bg-success rounded-circle d-flex align-items-center justify-content-center position-absolute" style={{ width: 32, height: 32, bottom: -16, left: 'calc(50% - 16px)' }}>
                  <i className="h4 mdi mdi-check m-0"></i>
                </div>
              )}
            </button>
          </div>
        ))}
      </div>

      {totalWorkshops > workshops.length && (
        <div className="mt-4 mb-2 d-flex flex-column align-items-center">
          <Button variant="light" onClick={this.props.fetchWorkshopsMore} disabled={workshopsLoadingMore}>
            {workshopsLoadingMore ? (
              <>
                <Spinner size="sm" animation="border" className="align-middle mr-1" />
                Loading more
              </>
            ) : 'Load more'}
          </Button>
        </div>
      )}
    </React.Fragment>;
  }

  renderLoading() {
    return (
      <div className="card card-body d-flex align-items-center justify-content-center mt-3" style={{ minHeight: 360 }}>
        <Spinner animation="border" />
      </div>
    );
  }

  render() {
    // tslint:disable-next-line:no-shadowed-variable
    const { selectedCenter, centers, workshopsLoading } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>Select workshop - Workshop Enrolments - Booking Autopilot</title>
        </Helmet>
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex flex-row align-items-center flex-grow-1 mt-3 mb-2">
            <h4 className="mr-1 flex-grow-1">Choose Workshop</h4>
            <div className="d-flex flex-row">
              <select className="form-control form-control-sm" value={selectedCenter.id} onChange={this.handleChangeCenter}>
                {centers.map(center => <option value={center.id} key={String(center.id)}>{center.name}</option>)}
              </select>
            </div>
          </div>
        </div>

        {workshopsLoading && this.renderLoading()}
        {!workshopsLoading && this.renderWorkshops()}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  fetchWorkshops: () => dispatch(fetchWorkshops()),
  fetchWorkshopsMore: () => dispatch(fetchWorkshopsMore()),
  changeCenter: (id: number) => dispatch(changeCenter(id)),
  selectWorkshop: (workshop) => {
    analytics.event('select_workshop', {
      workshopId: workshop.id,
    });
    dispatch(selectWorkshop(workshop));
    dispatch(changeScreen({ path: PATH.WORKSHOP_SESSIONS, params: { workshopId: workshop.id } }));
  }
});

const mapStateToProps = (state: IAppState): IStateProps => {
  const screenState = selectState(state).screens.chooseWorkshop;
  return {
    centers: selectCenters(state),
    selectedCenter: selectedCenter(state),
    workshops: screenState.workshops,
    workshopsLoading: screenState.loading,
    workshopsLoadingMore: screenState.loadingMore,
    totalWorkshops: screenState.total,
    selectedWorkshop: selectState(state).selectedWorkshop
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseWorkshop);
