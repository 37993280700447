import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { IChild, IParent } from '../../shared-components/parent-search/parent-search.types';
import { selectState } from './workshop-enrolment.selectors';
import httpHelpers from '@app/helpers/http.helpers';
import history from '@app/helpers/history.helper';
import { store } from '@app/store';
import Config from '@app/config';
import { setGlobalMessageBox } from '@app/actions/root';
import { generatePath } from 'react-router';
import { IChangeScreen, IRouteParams } from './workshop-enrolment.types';
import { IWorkshop, IWorkshopSession } from 'types/IWorkshop';
import { IFormValueCreateChild, IFormValueCreateParent } from '../../shared-components/create-parent/create-parent.types';
import analytics from '@app/helpers/analytics.helper';
import { PATH } from './workshop-enrolment.constants';
import { changeCenter as authChangeCenter } from '@app/actions/auth';

import {
  changeCenter,
  changeScreen,
  createChild,
  createChildFailed,
  createChildSuccess,
  createParent,
  createParentFailed,
  createParentSuccess,
  fetchWorkshops,
  fetchWorkshopsSuccess,
  fetchWorkshopsMore,
  fetchWorkshopsMoreSuccess,
  initLoad,
  initLoadFail,
  initLoadSuccess,
  setSelectedChild,
  setSelectedParent,
  setSelectedWorkshop,
  fetchWorkshopSessionsSuccess,
  fetchWorkshopSessions,
  setSelectedWorkshopSessions,
  startBooking,
  fetchPaymentMethodsSuccess,
  fetchPaymentMethods,
  selectPaymentMethod,
  fetchPaymentSuccess,
  fetchPayment,
  setLoaderVisibleState,
  applyPromocode,
  removePromocode,
  applyPromocodeSuccess,
  applyPromocodeFail,
  removePromocodeSuccess,
  removePromocodeFail,
  makePayment,
  makePaymentSuccess
} from './workshop-enrolment.actions';
import { IPaginationResponse } from 'types/common';
import { IMakePayment, IPayment, IPaymentMethod } from '@app/types';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { PAYMENT_SESSION_KEY } from '../../pages/payment-callback/payment-callback.constants';

function* initLoadSaga(action: Action<IRouteParams>) {
  try {
    const { parentId, childId, workshopId, sessionIds } = action.payload;
    if (parentId) {
      const parent: IParent = yield httpHelpers.get(`${Config.SERVER_URL}/api/parents/${parentId}`);
      if (!parent) {
        throw new Error('Parent not found');
      }
      yield put(setSelectedParent(parent));

      if (childId) {
        const child: IChild = yield httpHelpers.get(`${Config.SERVER_URL}/api/children/${childId}`);
        if (!child) {
          throw new Error('Child not found');
        }
        yield put(setSelectedChild(child));
      }

      if (workshopId) {
        const workshop = yield httpHelpers.get(`${Config.SERVER_URL}/api/workshops/${workshopId}`);

        if (!workshop) {
          throw new Error('Workshop not found');
        }
        yield put(setSelectedWorkshop(workshop));

        if (sessionIds) {
          const workshopSessions = yield httpHelpers.get(`${Config.SERVER_URL}/api/workshops/${workshop.id}/sessions`);
          const selectedSessions = workshopSessions.filter(x => sessionIds.includes(x.id));
          yield put(setSelectedWorkshopSessions(selectedSessions));
        }
      }
    }

    yield put(initLoadSuccess());
  } catch (err) {
    // tslint:disable-next-line: no-console
    console.log(err);
    yield put(initLoadFail());
  }
}

function* changeScreenSaga(action: Action<IChangeScreen>) {
  try {
    const path = action.payload.path;
    const params = action.payload.params || {} as IRouteParams;
    const replace = action.payload.replace;

    const state = selectState(store.getState());
    const selectedParent: IParent = state.selectedParent || {} as any;
    const selectedChild: IChild = state.selectedChild || {} as any;
    const selectedWorkshop = state.selectedWorkshop || {} as IWorkshop;
    const selectedSessions = state.selectedSessions || [] as IWorkshopSession[];

    const generagedPath = generatePath(path, {
      parentId: params.parentId || selectedParent.id,
      childId: params.childId || selectedChild.id,
      workshopId: params.workshopId || selectedWorkshop.id,
      sessionIds: params.sessionIds ? params.sessionIds.join(',') :
        selectedSessions.map(x => x.id).join(',')
    });

    if (replace) {
      history.replace(generagedPath);
    } else {
      history.push(generagedPath);
    }
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* createParentSaga(action: Action<IFormValueCreateParent>) {
  let parent: IParent;
  analytics.event('create_parent');
  try {
    const parentBody = { ...action.payload };
    delete parentBody.childs;
    const childs = action.payload.childs;
    parent = yield httpHelpers.post(`${Config.SERVER_URL}/api/parents`, parentBody);

    const createdChilds: IChild[] = [];

    for (const c of childs) {
      const formValue: IFormValueCreateChild = {
        ...c,
        parentId: parent.id,
      };

      const child: IChild = yield httpHelpers.post(`${Config.SERVER_URL}/api/children`, formValue);
      createdChilds.push(child);
    }

    yield put(setSelectedParent(parent));
    yield put(createParentSuccess());

    // if (createdChilds.length === 1) {
    //   yield put(setSelectedChild(createdChilds[0]));
    //   yield put(changeScreen({ path: PATH.PARENT_DETAIL, replace: true, params: { parentId: parent.id } }));
    //   yield put(changeScreen({ path: PATH.CHOOSE_CLASS, params: { parentId: parent.id, childId: createdChilds[0].id } }));
    // } else {
    yield put(changeScreen({ path: PATH.PARENT_DETAIL, replace: true, params: { parentId: parent.id } }));
    // }
  } catch (err) {
    if (parent) {
      yield put(setSelectedParent(parent));
      yield put(setGlobalMessageBox({ title: 'Error', message: 'Parent created successfully but something goes wrong while create child.', type: 'error' }));
      yield put(changeScreen({ path: PATH.PARENT_DETAIL, replace: true, params: { parentId: parent.id } }));
    } else {
      if (err.data && err.data.modelState) {
        const messages = Object.keys(err.data.modelState).map(key => err.data.modelState[key]);
        const htmlMessage = messages.map(m => '<div>' + m + '</div>').join('');
        yield put(setGlobalMessageBox({ title: 'Error', message: htmlMessage, type: 'error', isHTML: true }));
      } else {
        yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
      }
    }
    yield put(createParentFailed());
  }
}

function* createChildSaga(action: Action<IFormValueCreateChild>) {
  analytics.event('create_child');
  try {
    const selectedParent = selectState(store.getState()).selectedParent;

    const formValue: IFormValueCreateChild = {
      ...action.payload,
      parentId: selectedParent.id,
    };

    const child: IChild = yield httpHelpers.post(`${Config.SERVER_URL}/api/children`, formValue);
    yield put(createChildSuccess(child));
    yield put(changeScreen({ path: PATH.CHOOSE_WORKSHOP, params: { childId: child.id } }));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    yield put(createChildFailed());
  }
}

function* fetchWorkshopsSaga() {
  try {
    const center = store.getState().auth.selectedCenter;
    const workshops: IPaginationResponse<IWorkshop> = yield httpHelpers.get(`${Config.SERVER_URL}/api/workshops?centerId=${center.id}`);
    yield put(fetchWorkshopsSuccess(workshops));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* fetchWorkshopsMoreSaga() {
  try {
    const center = store.getState().auth.selectedCenter;
    const currentPage = selectState(store.getState()).screens.chooseWorkshop.currentPage;
    const workshops: IPaginationResponse<IWorkshop> = yield httpHelpers.get(`${Config.SERVER_URL}/api/workshops?centerId=${center.id}&pageNumber=${currentPage + 1}`);
    yield put(fetchWorkshopsMoreSuccess(workshops));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* changeCenterSaga(action: Action<any>) {
  try {
    yield put(authChangeCenter(action.payload));
    yield put(fetchWorkshops());
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* fetchWorkshopSessionsSaga() {
  try {
    const selectedWorkshop = selectState(store.getState()).selectedWorkshop;
    const workshopSessions: IWorkshopSession[] = yield httpHelpers.get(`${Config.SERVER_URL}/api/workshops/${selectedWorkshop.id}/sessions`);

    yield put(fetchWorkshopSessionsSuccess(workshopSessions));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* fetchPaymentMethodsSaga(action: Action<any>) {
  try {
    const center = store.getState().auth.selectedCenter;
    const paymentMethods: IPaymentMethod[] = yield httpHelpers.get(`${Config.SERVER_URL}/api/booking/payment?centerId=${center.id}`);

    yield put(fetchPaymentMethodsSuccess(paymentMethods));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* startBookingSaga() {
  try {
    const workshop = selectState(store.getState()).selectedWorkshop;
    const parent = selectState(store.getState()).selectedParent;
    const child = selectState(store.getState()).selectedChild;
    const selectedSessions = selectState(store.getState()).selectedSessions;

    const result = yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/workshop`, {
      parentId: parent.id,
      childId: child.id,
      workshopId: workshop.id,
      sessionIds: selectedSessions.map(x => x.id),
      paymentMethodName: ''
    });

    const paymentId = result.paymentId;

    yield fetchPaymentSaga({ type: '', payload: paymentId });
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    yield put(changeScreen({ path: PATH.WORKSHOP_SESSIONS, replace: true }));
  }
}

function* selectPaymentMethodSaga(action: Action<IPaymentMethod>) {
  analytics.event('select_payment_method', {
    paymentMethod: action.payload.name,
  });
  try {
    const paymentId = selectState(store.getState()).payment.id;

    yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/paymentMethod`, {
      paymentId,
      paymentMethodName: action.payload.name,
    });

    yield put(fetchPayment(paymentId));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* fetchPaymentSaga(action: Action<number>) {
  try {
    const payment = selectState(store.getState()).payment;

    const updatedPayment: IPayment = yield httpHelpers.get(`${Config.SERVER_URL}/api/paymentHistory/${action.payload}`);

    yield put(fetchPaymentSuccess({
      ...updatedPayment,
      promoCodes: payment ? (payment.promoCodes || []) : [],
      id: action.payload
    } as any));
    yield put(setLoaderVisibleState(false));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    yield put(setLoaderVisibleState(false));
  }
}

function* applyPromocodeSaga(action: Action<string>) {
  try {
    const paymentId = selectState(store.getState()).payment.id;

    yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/promoCode`, {
      paymentId,
      promoCode: action.payload,
    });

    yield put(applyPromocodeSuccess(action.payload));
    yield fetchPaymentSaga({ type: '', payload: paymentId });
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    yield put(applyPromocodeFail());
  }
}

function* removePromocodeSaga(action: Action<any>) {
  try {
    const paymentId = selectState(store.getState()).payment.id;

    yield httpHelpers.delete(`${Config.SERVER_URL}/api/booking/promoCode`, {
      paymentId,
      promoCode: action.payload,
    });

    yield put(removePromocodeSuccess(action.payload));
    yield fetchPaymentSaga({ type: '', payload: paymentId });
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    yield put(removePromocodeFail());
  }
}

function* makePaymentSaga(action: Action<IMakePayment>) {
  analytics.event('confirm_booking');
  if (action.payload.stripe) {
    const successCallback =
      window.origin + "/payment-callback/success/" + action.payload.paymentId;
    const failCallback =
      window.origin + "/payment-callback/failed/" + action.payload.paymentId;
    try {
      const paymentResponse = yield httpHelpers.post(
        `${Config.SERVER_URL}/api/booking/payment`,
        {
          paymentId: action.payload.paymentId,
          paymentMethodName: action.payload.paymentMethodName
        }
      );

      if (!paymentResponse.clientAuthorizationToken) {
        throw new Error("clientAuthorizationToken is required");
      }
      const cardElement = action.payload.elements.getElement(CardNumberElement);
      yield action.payload.stripe.confirmCardPayment(
        paymentResponse.clientAuthorizationToken,
        {
          payment_method: {
            card: cardElement
          }
        }
      );

      const data = action.payload;
      data.returnUrl = successCallback;
      data.cancelUrl = failCallback;

      yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/process`, {
        paymentId: action.payload.paymentId,
        returnUrl: successCallback,
        cancelUrl: failCallback
      });

      yield put(
        changeScreen({
          path: `/workshop-enrolment/complete/${action.payload.paymentId}`
        })
      );
      yield put(makePaymentSuccess());
    } catch (err) {
      yield put(
        setGlobalMessageBox({
          title: "Error",
          message: err.message,
          type: "error",
          isHTML: true
        })
      );
      yield put(setLoaderVisibleState(false));

      yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/cancel`, {
        paymentId: action.payload.paymentId,
        returnUrl: successCallback,
        cancelUrl: failCallback
      });
    }
    return;
  }
  try {
    const successCallback = window.origin + '/payment-callback/success/' + action.payload.paymentId;
    const failCallback = window.origin + '/payment-callback/failed/' + action.payload.paymentId;

    const data = action.payload;
    data.returnUrl = successCallback;
    data.cancelUrl = failCallback;

    const storage: any = {
      backToUrl: window.location.pathname,
      completeUrl: `/workshop-enrolment/complete/${action.payload.paymentId}`,
      paymentId: action.payload.paymentId,
    };

    const paymentResponse = yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/payment`, action.payload);

    if (paymentResponse.redirectUrl) {
      sessionStorage.setItem(PAYMENT_SESSION_KEY, JSON.stringify(storage));
      window.location.href = paymentResponse.redirectUrl;
      return;
    }

    const processResponse = yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/process`, {
      paymentId: action.payload.paymentId,
      returnUrl: successCallback,
      cancelUrl: failCallback,
    });

    if (processResponse.redirectUrl) {
      const bodyParams = JSON.parse(processResponse.httpBodyParams || '{}');
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = processResponse.redirectUrl;
      form.style.display = 'none';
      Object.keys(bodyParams).forEach(key => {
        const input = document.createElement('input');
        input.name = key;
        input.value = bodyParams[key];
        input.type = 'hidden';
        form.appendChild(input);
      });
      document.body.appendChild(form);
      storage.processed = true;
      sessionStorage.setItem(PAYMENT_SESSION_KEY, JSON.stringify(storage));
      form.submit();
      return;
    }

    yield put(changeScreen({ path: `/workshop-enrolment/complete/${action.payload.paymentId}` }));
    yield put(makePaymentSuccess());
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    yield put(setLoaderVisibleState(false));
  }
}

export default [
  takeLatest(initLoad.toString(), initLoadSaga),
  takeLatest(changeScreen.toString(), changeScreenSaga),
  takeLatest(createParent.toString(), createParentSaga),
  takeLatest(createChild.toString(), createChildSaga),
  takeLatest(fetchWorkshops.toString(), fetchWorkshopsSaga),
  takeLatest(fetchWorkshopsMore.toString(), fetchWorkshopsMoreSaga),
  takeLatest(changeCenter.toString(), changeCenterSaga),
  takeLatest(fetchWorkshopSessions.toString(), fetchWorkshopSessionsSaga),
  takeLatest(fetchPaymentMethods.toString(), fetchPaymentMethodsSaga),
  takeLatest(startBooking.toString(), startBookingSaga),
  takeLatest(selectPaymentMethod.toString(), selectPaymentMethodSaga),
  takeLatest(fetchPayment.toString(), fetchPaymentSaga),
  takeLatest(applyPromocode.toString(), applyPromocodeSaga),
  takeLatest(removePromocode.toString(), removePromocodeSaga),
  takeLatest(makePayment.toString(), makePaymentSaga)
];