import Moment from 'moment-timezone';
import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';

import { changeCenter as authChangeCenter, changeTerm as authChangeTerm } from '@app/actions/auth';
import Config from '@app/config';
import history from '@app/helpers/history.helper';
import httpHelpers from '@app/helpers/http.helpers';
import { store } from '@app/store';
import { IPaginationResponse, IPayment, IPaymentMethod, IWeeklyProgram, IMakePayment } from '@app/types';
import {
  IBookingResponse,
  IChangeScreen,
  IChild,
  IChildrenPaymentTypeDetail,
  IParent,
  IRouteParams,
} from './manual-enrolment.types';

import { setGlobalMessageBox } from '@app/actions/root';
import analytics from '@app/helpers/analytics.helper';
import { IProgram } from 'pages/lesson-attendance/lesson-attendance.types';
import { ILessonDates } from 'pages/manage-attendance/manage-attendance.types';
import { generatePath } from 'react-router';
import {
  applyPromocode,
  applyPromocodeFail,
  applyPromocodeSuccess,
  changeCenter,
  changeScreen,
  changeTerm,
  createChild,
  // fetchBooking,
  // fetchBookingSuccess,
  createChildFailed,
  createChildSuccess,
  createParent,
  createParentFailed,
  createParentSuccess,
  fetchChilds,
  fetchChildsSuccess,
  fetchLessonDates,
  fetchLessonDatesSuccess,
  fetchPayment,
  fetchPaymentMethods,
  fetchPaymentMethodsSuccess,
  fetchPaymentSuccess,
  fetchWeeklyPrograms,
  fetchWeeklyProgramsSuccess,
  ignoreChildAgeWarning,
  initLoad,
  initLoadFail,
  initLoadSuccess,
  makePayment,
  makePaymentSuccess,
  removePromocode,
  removePromocodeFail,
  removePromocodeSuccess,
  selectPaymentMethod,
  selectProgram,
  setChildAgeError,
  setLessonDatePaymentOptions,
  setLoaderVisibleState,
  setProgramAvailableState,
  setSelectedChild,
  setSelectedDate,
  setSelectedParent,
  setSelectedPaymentType,
  setSelectedProgram,
  startBooking,
} from './manual-enrolment.actions';
import { PATH } from './manual-enrolment.constants';
import { selectState } from './manual-enrolment.selectors';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { IFormValueParentSearch } from '../../shared-components/parent-search/parent-search.types';
import {
  IFormValueCreateChild,
  IFormValueCreateParent
} from '../../shared-components/create-parent/create-parent.types';
import { PAYMENT_SESSION_KEY } from '../../pages/payment-callback/payment-callback.constants';

function* fetchChildsSaga(action: Action<IFormValueParentSearch>) {
  try {
    const selectedParent = selectState(store.getState()).selectedParent;

    const result: IPaginationResponse<IChild> = yield httpHelpers.get(`${Config.SERVER_URL}/api/children?parentId=${selectedParent.id}&pageNumber=1&pageSize=10`);
    yield put(fetchChildsSuccess(result.data));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* fetchWeeklyProgramsSaga() {
  try {
    const center = store.getState().auth.selectedCenter;
    const term = store.getState().auth.selectedTerm;
    const programs: IWeeklyProgram[] = yield httpHelpers.get<IWeeklyProgram[]>(`${Config.SERVER_URL}/api/weeklyPrograms/unpaged?termId=${term.id}&centerId=${center.id}`);
    yield put(fetchWeeklyProgramsSuccess(programs));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* changeCenterSaga(action: Action<any>) {
  try {
    yield put(authChangeCenter(action.payload));
    yield put(fetchWeeklyPrograms());
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* changeTermSaga(action: Action<any>) {
  try {
    yield put(authChangeTerm(action.payload));
    yield put(fetchWeeklyPrograms());
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* fetchPaymentMethodsSaga(action: Action<any>) {
  try {
    const center = store.getState().auth.selectedCenter;
    const paymentMethods: IPaymentMethod[] = yield httpHelpers.get(`${Config.SERVER_URL}/api/booking/payment?centerId=${center.id}`);

    yield put(fetchPaymentMethodsSuccess(paymentMethods));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* fetchLessonDatesSaga(action: Action<any>) {
  try {
    const selectedProgram = selectState(store.getState()).selectedProgram;
    const lessonDates: ILessonDates = yield httpHelpers.get<ILessonDates>(`${Config.SERVER_URL}/api/weeklyPrograms/lessonDates?id=${selectedProgram.id}`);

    yield put(fetchLessonDatesSuccess(lessonDates.lessonDates.map(x => ({ date: x, childrenPaymentTypes: [], notAvailable: false }))));

    const program = selectState(store.getState()).selectedProgram;
    const parent = selectState(store.getState()).selectedParent;
    const child = selectState(store.getState()).selectedChild;

    for (const date of lessonDates.lessonDates) {
      try {
        const result: IBookingResponse = yield httpHelpers.get(`${Config.SERVER_URL}/api/booking?programId=${program.id}&parentId=${parent.id}&childId=${child.id}&date=${date}&validateChildAge=false`);
        yield put(setLessonDatePaymentOptions({ date, programId: program.id, childrenPaymentTypes: result.childrenPaymentTypes, notAvailable: false }));
      } catch (error) {
        let message: string = error.message;

        if (message && message.includes('already made a booking')) {
          message = 'Already enroled in this class';
        }

        yield put(setLessonDatePaymentOptions({ date, programId: program.id, childrenPaymentTypes: [], notAvailable: true, message }));
      }
    }
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* createChildSaga(action: Action<IFormValueCreateChild>) {
  analytics.event('create_child');
  try {
    const selectedParent = selectState(store.getState()).selectedParent;

    const formValue: IFormValueCreateChild = {
      ...action.payload,
      parentId: selectedParent.id,
    };

    const child: IChild = yield httpHelpers.post(`${Config.SERVER_URL}/api/children`, formValue);
    yield put(createChildSuccess(child));
    yield put(changeScreen({ path: PATH.CHOOSE_CLASS, params: { childId: child.id } }));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    yield put(createChildFailed());
  }
}

// function* fetchBookingSaga(action: Action<any>) {
//   try {
//     const program = selectState(store.getState()).selectedProgram;
//     const parent = selectState(store.getState()).selectedParent;
//     const child = selectState(store.getState()).selectedChild;
//     const selectedDate = selectState(store.getState()).selectedDate;

//     const result: IBookingResponse = yield httpHelpers.get(`${Config.SERVER_URL}/api/booking?programId=${program.id}&parentId=${parent.id}&childId=${child.id}&date=${selectedDate}`);
//     yield put(fetchBookingSuccess(result.childrenPaymentTypes));
//   } catch (err) {
//     yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
//     yield put(changeScreen('choose-class'));
//   }
// }

function* startBookingSaga() {
  try {
    const program = selectState(store.getState()).selectedProgram;
    const parent = selectState(store.getState()).selectedParent;
    const child = selectState(store.getState()).selectedChild;
    const selectedDate = selectState(store.getState()).selectedDate;
    const selectedPaymentType = selectState(store.getState()).selectedPaymentType;

    const result = yield httpHelpers.post(`${Config.SERVER_URL}/api/booking`, {
      parentId: parent.id,
      childId: child.id,
      programId: program.id,
      startDate: selectedDate,
      paymentType: selectedPaymentType.id,
      isPrepaid: false,
      paymentMethodName: '',
      isBonusLesson: false,
      validateChildAge: false,
    });

    const paymentId = result.paymentId;

    yield fetchPaymentSaga({ type: '', payload: paymentId });
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* selectPaymentMethodSaga(action: Action<IPaymentMethod>) {
  analytics.event('select_payment_method', {
    paymentMethod: action.payload.name,
  });
  try {
    const paymentId = selectState(store.getState()).payment.id;

    yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/paymentMethod`, {
      isInstalmentPayment: false,
      paymentId,
      paymentMethodName: action.payload.name,
    });

    yield put(fetchPayment(paymentId));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* fetchPaymentSaga(action: Action<number>) {
  try {
    // const selectedPaymentMethod = selectState(store.getState()).selectedPaymentMethod;
    const payment = selectState(store.getState()).payment;

    const updatedPayment: IPayment = yield httpHelpers.get(`${Config.SERVER_URL}/api/paymentHistory/${action.payload}`);

    yield put(fetchPaymentSuccess({
      ...updatedPayment,
      promoCodes: payment ? (payment.promoCodes || []) : [],
      id: action.payload,
      // paymentMethod: {
      //   name: selectedPaymentMethod ? selectedPaymentMethod.name : '',
      // },
    } as any));
    yield put(setLoaderVisibleState(false));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    yield put(setLoaderVisibleState(false));
  }
}

function* makePaymentSaga(action: Action<IMakePayment>) {
  analytics.event('confirm_booking');
  if (action.payload.stripe) {
    const successCallback =
      window.origin + "/payment-callback/success/" + action.payload.paymentId;
    const failCallback =
      window.origin + "/payment-callback/failed/" + action.payload.paymentId;
    try {
      const paymentResponse = yield httpHelpers.post(
        `${Config.SERVER_URL}/api/booking/payment`,
        {
          paymentId: action.payload.paymentId,
          paymentMethodName: action.payload.paymentMethodName
        }
      );

      if (!paymentResponse.clientAuthorizationToken) {
        throw new Error("clientAuthorizationToken is required");
      }
      const cardElement = action.payload.elements.getElement(CardNumberElement);
      yield action.payload.stripe.confirmCardPayment(
        paymentResponse.clientAuthorizationToken,
        {
          payment_method: {
            card: cardElement
          }
        }
      );

      const data = action.payload;
      data.returnUrl = successCallback;
      data.cancelUrl = failCallback;

      yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/process`, {
        paymentId: action.payload.paymentId,
        returnUrl: successCallback,
        cancelUrl: failCallback
      });

      yield put(
        changeScreen({
          path: `/manual-enrolment/complete/${action.payload.paymentId}`
        })
      );
      yield put(makePaymentSuccess());
    } catch (err) {
      yield put(
        setGlobalMessageBox({
          title: "Error",
          message: err.message,
          type: "error",
          isHTML: true
        })
      );
      yield put(setLoaderVisibleState(false));

      yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/cancel`, {
        paymentId: action.payload.paymentId,
        returnUrl: successCallback,
        cancelUrl: failCallback
      });
    }
    return;
  }
  try {
    const successCallback = window.origin + '/payment-callback/success/' + action.payload.paymentId;
    const failCallback = window.origin + '/payment-callback/failed/' + action.payload.paymentId;

    const data = action.payload;
    data.returnUrl = successCallback;
    data.cancelUrl = failCallback;

    const storage: any = {
      backToUrl: window.location.pathname,
      completeUrl: `/manual-enrolment/complete/${action.payload.paymentId}`,
      paymentId: action.payload.paymentId,
    };

    const paymentResponse = yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/payment`, action.payload);

    if (paymentResponse.redirectUrl) {
      sessionStorage.setItem(PAYMENT_SESSION_KEY, JSON.stringify(storage));
      window.location.href = paymentResponse.redirectUrl;
      return;
    }

    const processResponse = yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/process`, {
      paymentId: action.payload.paymentId,
      returnUrl: successCallback,
      cancelUrl: failCallback,
    });

    if (processResponse.redirectUrl) {
      const bodyParams = JSON.parse(processResponse.httpBodyParams || '{}');
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = processResponse.redirectUrl;
      form.style.display = 'none';
      Object.keys(bodyParams).forEach(key => {
        const input = document.createElement('input');
        input.name = key;
        input.value = bodyParams[key];
        input.type = 'hidden';
        form.appendChild(input);
      });
      document.body.appendChild(form);
      storage.processed = true;
      sessionStorage.setItem(PAYMENT_SESSION_KEY, JSON.stringify(storage));
      form.submit();
      return;
    }

    yield put(changeScreen({ path: `/manual-enrolment/complete/${action.payload.paymentId}` }));
    yield put(makePaymentSuccess());
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    yield put(setLoaderVisibleState(false));
  }
}

function* applyPromocodeSaga(action: Action<string>) {
  try {
    const paymentId = selectState(store.getState()).payment.id;

    yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/promoCode`, {
      paymentId,
      promoCode: action.payload,
    });

    yield put(applyPromocodeSuccess(action.payload));
    yield fetchPaymentSaga({ type: '', payload: paymentId });
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    yield put(applyPromocodeFail());
  }
}

function* removePromocodeSaga(action: Action<any>) {
  try {
    const paymentId = selectState(store.getState()).payment.id;

    yield httpHelpers.delete(`${Config.SERVER_URL}/api/booking/promoCode`, {
      paymentId,
      promoCode: action.payload,
    });

    yield put(removePromocodeSuccess(action.payload));
    yield fetchPaymentSaga({ type: '', payload: paymentId });
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    yield put(removePromocodeFail());
  }
}

function* selectProgramSaga(action: Action<IProgram>) {
  try {
    yield put(setLoaderVisibleState(true));
    const program = action.payload;

    if (program.attendingChildrenCount >= program.maxChildrenRest) {
      throw new Error('This class is fully booked. Please select a different class.');
    }

    const lessonDates: ILessonDates = yield httpHelpers.get<ILessonDates>(`${Config.SERVER_URL}/api/weeklyPrograms/lessonDates?id=${program.id}`);

    const parent = selectState(store.getState()).selectedParent;
    const child = selectState(store.getState()).selectedChild;
    const selectedDate = lessonDates.lessonDates[0];

    try {
      yield httpHelpers.get(`${Config.SERVER_URL}/api/booking?programId=${program.id}&parentId=${parent.id}&childId=${child.id}&date=${selectedDate}`);
    } catch (err) {
      if (err.message.indexOf('already made a booking for this class') === -1) {
        throw err;
      }
    }

    yield put(setProgramAvailableState(true));
    yield put(setLoaderVisibleState(false));
    yield put(changeScreen({ path: PATH.LESSON_DATES, params: { programId: program.id } }));
  } catch (err) {
    yield put(setProgramAvailableState(false));
    yield put(setLoaderVisibleState(false));
    if (err.message && err.message.indexOf(`not appropriate for your child's age`) !== -1) {
      yield put(setChildAgeError(err.message));
      return;
    }
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* ignoreChildAgeWarningSaga(action: Action<any>) {
  try {
    yield put(setLoaderVisibleState(true));
    const program = selectState(store.getState()).selectedProgram;

    const lessonDates: ILessonDates = yield httpHelpers.get<ILessonDates>(`${Config.SERVER_URL}/api/weeklyPrograms/lessonDates?id=${program.id}`);

    const parent = selectState(store.getState()).selectedParent;
    const child = selectState(store.getState()).selectedChild;
    const selectedDate = lessonDates.lessonDates[0];

    try {
      yield httpHelpers.get(`${Config.SERVER_URL}/api/booking?programId=${program.id}&parentId=${parent.id}&childId=${child.id}&date=${selectedDate}&validateChildAge=false`);
    } catch (err) {
      if (err.message.indexOf('already made a booking for this class') === -1) {
        throw err;
      }
    }

    yield put(setProgramAvailableState(true));
    yield put(setLoaderVisibleState(false));
    yield put(changeScreen({ path: PATH.LESSON_DATES, params: { programId: program.id } }));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    yield put(setProgramAvailableState(false));
    yield put(setLoaderVisibleState(false));
  }
}

function* initLoadSaga(action: Action<IRouteParams>) {
  try {
    const { parentId, childId, programId, startDate, paymentTypeId } = action.payload;
    if (parentId) {
      const parent: IParent = yield httpHelpers.get(`${Config.SERVER_URL}/api/parents/${parentId}`);
      if (!parent) {
        throw new Error('Parent not found');
      }
      yield put(setSelectedParent(parent));

      if (childId) {
        const child: IChild = yield httpHelpers.get(`${Config.SERVER_URL}/api/children/${childId}`);
        if (!child) {
          throw new Error('Child not found');
        }
        yield put(setSelectedChild(child));
      }

      if (programId) {
        const program = yield httpHelpers.get(`${Config.SERVER_URL}/api/weeklyPrograms/${programId}`);

        if (!program) {
          throw new Error('Program not found');
        }
        yield put(setSelectedProgram(program));

        if (startDate) {
          const result: IBookingResponse = yield httpHelpers.get(`${Config.SERVER_URL}/api/booking?programId=${programId}&parentId=${parentId}&childId=${childId}&date=${Moment(startDate).format('YYYY-MM-DD')}&validateChildAge=false`);

          const paymentType = result.childrenPaymentTypes[0].paymentTypes.find(x => x.id === paymentTypeId);

          if (!paymentType) {
            throw new Error('Payment type not found');
          }

          yield put(setSelectedDate(startDate));
          yield put(setSelectedPaymentType(paymentType));
        }
      }
    }

    yield put(initLoadSuccess());
  } catch (err) {
    // tslint:disable-next-line: no-console
    console.log(err);
    yield put(initLoadFail());
  }
}

function* changeScreenSaga(action: Action<IChangeScreen>) {
  try {
    const path = action.payload.path;
    const params = action.payload.params || {} as IRouteParams;
    const replace = action.payload.replace;

    const state = selectState(store.getState());
    const selectedParent: IParent = state.selectedParent || {} as any;
    const selectedChild: IChild = state.selectedChild || {} as any;
    const selectedProgram = state.selectedProgram || {} as IWeeklyProgram;
    const selectedDate = state.selectedDate;
    const selectedPaymentType = state.selectedPaymentType || {} as IChildrenPaymentTypeDetail;

    const generagedPath = generatePath(path, {
      parentId: params.parentId || selectedParent.id,
      childId: params.childId || selectedChild.id,
      programId: params.programId || selectedProgram.id,
      startDate: Moment(params.startDate || selectedDate).format('YYYY-MM-DD'),
      paymentTypeId: params.paymentTypeId || selectedPaymentType.id,
    });

    if (replace) {
      history.replace(generagedPath);
    } else {
      history.push(generagedPath);
    }
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* createParentSaga(action: Action<IFormValueCreateParent>) {
  let parent: IParent;
  analytics.event('create_parent');
  try {
    const parentBody = { ...action.payload };
    delete parentBody.childs;
    const childs = action.payload.childs;
    parent = yield httpHelpers.post(`${Config.SERVER_URL}/api/parents`, parentBody);

    const createdChilds: IChild[] = [];

    for (const c of childs) {
      const formValue: IFormValueCreateChild = {
        ...c,
        parentId: parent.id,
      };

      const child: IChild = yield httpHelpers.post(`${Config.SERVER_URL}/api/children`, formValue);
      createdChilds.push(child);
    }

    yield put(setSelectedParent(parent));
    yield put(createParentSuccess());

    // if (createdChilds.length === 1) {
    //   yield put(setSelectedChild(createdChilds[0]));
    //   yield put(changeScreen({ path: PATH.PARENT_DETAIL, replace: true, params: { parentId: parent.id } }));
    //   yield put(changeScreen({ path: PATH.CHOOSE_CLASS, params: { parentId: parent.id, childId: createdChilds[0].id } }));
    // } else {
    yield put(changeScreen({ path: PATH.PARENT_DETAIL, replace: true, params: { parentId: parent.id } }));
    // }
  } catch (err) {
    if (parent) {
      yield put(setSelectedParent(parent));
      yield put(setGlobalMessageBox({ title: 'Error', message: 'Parent created successfully but something goes wrong while create child.', type: 'error' }));
      yield put(changeScreen({ path: PATH.PARENT_DETAIL, replace: true, params: { parentId: parent.id } }));
    } else {
      if (err.data && err.data.modelState) {
        const messages = Object.keys(err.data.modelState).map(key => err.data.modelState[key]);
        const htmlMessage = messages.map(m => '<div>' + m + '</div>').join('');
        yield put(setGlobalMessageBox({ title: 'Error', message: htmlMessage, type: 'error', isHTML: true }));
      } else {
        yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
      }
    }
    yield put(createParentFailed());
  }
}

export default [
  takeLatest(fetchChilds.toString(), fetchChildsSaga),
  takeLatest(fetchWeeklyPrograms.toString(), fetchWeeklyProgramsSaga),
  takeLatest(changeCenter.toString(), changeCenterSaga),
  takeLatest(changeTerm.toString(), changeTermSaga),
  takeLatest(fetchPaymentMethods.toString(), fetchPaymentMethodsSaga),
  takeLatest(fetchLessonDates.toString(), fetchLessonDatesSaga),
  takeLatest(createChild.toString(), createChildSaga),
  // takeLatest(fetchBooking.toString(), fetchBookingSaga),
  takeLatest(startBooking.toString(), startBookingSaga),
  takeLatest(selectPaymentMethod.toString(), selectPaymentMethodSaga),
  takeLatest(fetchPayment.toString(), fetchPaymentSaga),
  takeLatest(makePayment.toString(), makePaymentSaga),
  takeLatest(applyPromocode.toString(), applyPromocodeSaga),
  takeLatest(removePromocode.toString(), removePromocodeSaga),
  takeLatest(selectProgram.toString(), selectProgramSaga),
  takeLatest(initLoad.toString(), initLoadSaga),
  takeLatest(changeScreen.toString(), changeScreenSaga),
  takeLatest(createParent.toString(), createParentSaga),
  takeLatest(ignoreChildAgeWarning.toString(), ignoreChildAgeWarningSaga),
];
