import { IMakePayment, IPayment, IPaymentMethod } from "@app/types";
import React from "react";
import GeneralPayment from "./general-payment";
import StripePayment from "./stripe-payment";

interface IProps {
    payment: IPayment;
    selectedPaymentMethod: IPaymentMethod;
    onMakePayment: (payment: IMakePayment) => void;
}

const PaymentGateway: React.FC<IProps> = (props) => {
    const { payment, selectedPaymentMethod, onMakePayment } = props;

    if (!selectedPaymentMethod || !payment) {
        return null;
    }

    switch (selectedPaymentMethod.name.toLowerCase()) {
        case 'stripe':
            return <StripePayment
                payment={payment}
                selectedPaymentMethod={selectedPaymentMethod}
                onMakePayment={onMakePayment} />;
        case 'banktransfer':
        case 'easypay':
        case 'saferpay':
        case 'epay':
        case 'paypalexpress':
        case 'gocardless':
        case 'instore':
        case 'voucher':
        case 'cloverconnect':
            return <GeneralPayment
                payment={payment}
                selectedPaymentMethod={selectedPaymentMethod}
                onMakePayment={onMakePayment}
            />;
    }

    return null;
}

export default PaymentGateway;