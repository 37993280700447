import { reloadLanguages, setGlobalMessageBox } from '@app/actions/root';
import Config from '@app/config';
import { fetchCentersSaga, fetchTermsSaga } from '@app/effects/auth.effects';
import analytics from '@app/helpers/analytics.helper';
import history from '@app/helpers/history.helper';
import { store } from '@app/store';
import * as QueryString from 'query-string';
import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { login, loginFail, loginSuccess, setIsAuthenticating } from '../../actions/auth';
import { ILoginParam, ILoginResponse } from '../../types/common';

const fetchLoginAPI = async (data: ILoginParam): Promise<ILoginResponse> => {
  const franchisor = store.getState().auth.franchisor;
  const response = await fetch(`${Config.SERVER_URL}/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Franchisor-Id': franchisor ? String(franchisor.data.id) : '',
    },
    body: `grant_type=password&username=${encodeURIComponent(data.username)}&password=${encodeURIComponent(data.password)}`,
  });

  const status = response.status;

  const result = await response.json();

  if (status !== 200) {
    throw new Error('Invalid username or password');
  }

  return result;
};

function* loginSaga(action: Action<ILoginParam>) {
  try {
    const result: ILoginResponse = yield fetchLoginAPI(action.payload);
    // yield new Promise(resolve => setTimeout(resolve, 2000));
    yield put(loginSuccess({
      expireAt: result['.expires'],
      issuedAt: result['.issued'],
      refreshToken: result.refresh_token,
      token: result.access_token,
      tokenType: result.token_type,
      user: JSON.parse(result.user),
    }));

    analytics.setUser({ userId: JSON.parse(result.user).id });
    analytics.event('login');

    yield fetchCentersSaga();
    yield fetchTermsSaga();

    const redirectUrl: any = QueryString.parse(history.location.search).redirect || '/';

    history.push(redirectUrl);

    yield put(setIsAuthenticating(false));
    yield put(reloadLanguages());
  } catch (err) {
    yield put(loginFail());
    yield put(setGlobalMessageBox({
      title: 'Login failed',
      message: err.message,
      type: 'error',
    }));
  }
}

export default [
  takeLatest(login.toString(), loginSaga),
];
